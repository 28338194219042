<template>
  <v-card>
    <v-card-title primary-title>
      <span v-if="appointments.length > 0">
        Please notify scheduled appointments before deleting this location.
      </span>
      <span v-if="appointments.length < 1">
        No scheduled appointments at this location. <br/>Are you sure you want to
        delete this location and all blocks?
      </span>
    </v-card-title>
     <v-card-text>
      <v-row v-if="loadingLocationAppointments">
        <v-col cols="12" sm="12" class="text-center">
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      dense
      :headers="headers"
      :items="appointments"
      class="elevation-1"
      v-if="appointments.length > 0"
    >
      <template v-slot:[`item.datetime_start`]="{ item }">
        <span>{{ getPrettyTime(item.datetime_start) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="error" x-small @click="releaseAppointment(item)" :disabled="releasingAppt"
          >Release</v-btn
        >
      </template>
    </v-data-table>
    <v-card-actions>
      <v-btn color="primary" @click="closeme()"> Cancel </v-btn>
      <v-btn
        color="error"
        :disabled="appointments.length > 0"
        @click="endSingle()"
      >
        Delete Location
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    locationId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      appointments: [],
      headers: [
        { text: "Start Date", value: "datetime_start" },
        { text: "Name", value: "employeeFullName" },
        { text: "Phone", value: "phone" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions("users", ["deleteAppointment"]),
    ...mapActions("vaccineLocations", ["getLocationAppts", "deleteLocation"]),
    releaseAppointment: function (item) {
      this.deleteAppointment({ appointmentId: item.id, personId: item.user_id }).then((response) => {
        this.getLocationAppts(this.locationId).then((response) => {
          this.appointments = response;
        });
      });
    },
    closeme() {
      this.$emit("closeReleaseList");
    },
    endSingle() {
      if (this.appointments.length < 1) {
        this.deleteLocation({ id: this.locationId }).then((response) => {
          this.$emit("closeReleaseList");
        });
      }
    },
    convertToLocal: function (date) {
      return moment.utc(date).local();
    },
    getPrettyTime: function (date) {
      return this.convertToLocal(date).format("MMM Do h:mm a");
    },
  },
  computed: {
    ...mapGetters("vaccineLocations", ["loadingLocationAppointments"]),
    ...mapGetters("users", ["releasingAppt"])
  },
  mounted() {
    if (this.locationId !== null) {
      this.getLocationAppts(this.locationId).then((response) => {
        this.appointments = response;
      });
    }
  },
};
</script>
